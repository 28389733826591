import { useState, useEffect } from "react";
import { Box, Center, Flex, Image } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import "./blink.css";
import { useAccountContext } from "@/Context/AccountContext";
import { initUtils } from "@telegram-apps/sdk";
// import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
import cup from '../../../public/img/winner-cup-7816.svg'
import "./pluse.css";

function Navbar() {
  const utils = initUtils();
  const [isWalletClicked, setIsWalletClicked] = useState(false);
  const [isCommunityClicked, setIsCommunityClicked] = useState(false);
  const { typeofChain, userID } = useAccountContext();
  useEffect(() => {
    // Kiểm tra localStorage để xác định xem nút nháy đỏ đã được nhấn hay chưa
    const walletClickedStatus = localStorage.getItem(
      `walletClicked ${typeofChain} ${userID}`
    );
    const communityClickedStatus = localStorage.getItem(
      `communityClicked ${typeofChain} ${userID}`
    );

    if (walletClickedStatus === "true") {
      setIsWalletClicked(true);
    }
    if (!communityClickedStatus && walletClickedStatus === "true") {
      setIsCommunityClicked(true);
    }
  }, []);

  const handleWalletClick = () => {
    setIsWalletClicked(true);
    setIsCommunityClicked(true);
    localStorage.setItem(`walletClicked ${typeofChain} ${userID}`, "true"); // Lưu trạng thái vào localStorage
  };

  const handleCommunityClick = () => {
    utils.openTelegramLink("https://t.me/dislandshub");
    setIsCommunityClicked(false);
    localStorage.setItem(`communityClicked ${typeofChain} ${userID}`, "true"); // Lưu trạng thái vào localStorage
  };

  return (
    <Center
      w={"95vw"}
      alignItems={"center"}
      justifyContent={"space-between"}
      zIndex={1000}
      my={"5px"}
      h={"40px"}
    >
      <Center h={"24px"} gap={"20px"}>
        <Box
          fontFamily={"Open sans"}
          fontSize={"24px"}
          fontWeight={700}
          textColor={"black"}
          as={RouterLink}
          to={"/mainpage"}
        >
          <i className="fa-solid fa-house"></i>
        </Box>
      </Center>

      <Flex position="relative">
        <Flex
          as={RouterLink}
          to="/leaderboard"
          textColor="white"
          fontFamily="Open Sans"
          fontWeight={700}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          cursor="pointer"
          transition="all 0.3s ease"
          textTransform="uppercase"
          _hover={{
            transform: "scale(1.05)",
          }}
          _active={{
            transform: "scale(0.98)",
          }}
          onClick={handleWalletClick}
        >
          <Image src={cup} h={'24px'} w={'50px'}/>
          {!isWalletClicked && (
            <Box
              position="absolute"
              top="-5px"
              left="-1px"
              width="12px"
              height="12px"
              bg="red.500"
              boxShadow="0 0 8px rgba(255, 0, 0, 0.7), 0 0 12px rgba(255, 0, 0, 0.5)"
              borderRadius="50%"
              animation="puls 1.5s infinite ease-in-out"
              zIndex={999}
            />
          )}
        </Flex>
      </Flex>
      <Flex h={"5vh"} gap={"10px"}>
        <Flex position="relative">
          {/* Nút JOIN OUR COMMUNITY */}
          <Flex
            textColor="white"
            bg="black"
            fontFamily="Open Sans"
            fontSize="10px"
            fontWeight={700}
            alignItems="center"
            justifyContent="center"
            w="40vw"
            textAlign="center"
            cursor="pointer"
            transition="all 0.3s ease"
            textTransform="uppercase"
            borderRadius="10px"
            _hover={{
              bg: "gray.800",
              boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.05)",
            }}
            _active={{
              transform: "scale(0.98)",
              boxShadow: "0 3px 5px rgba(0, 0, 0, 0.15)",
            }}
            onClick={handleCommunityClick}
          >
            JOIN OUR COMMUNITY
            {/* Nút nháy đỏ cho JOIN OUR COMMUNITY */}
            {isCommunityClicked && (
              <Box
                position="absolute"
                top="-5px"
                left="-1px"
                width="12px"
                height="12px"
                bg="red.500"
                boxShadow="0 0 8px rgba(255, 0, 0, 0.7), 0 0 12px rgba(255, 0, 0, 0.5)"
                borderRadius="50%"
                animation="puls 1.5s infinite ease-in-out"
                zIndex={999}
              />
            )}
          </Flex>
        </Flex>

        <Flex position="relative">
          {/* Nút WALLET */}
          <Flex
            as={RouterLink}
            to="/wallet"
            textColor="white"
            bg="black"
            fontFamily="Open Sans"
            fontSize="10px"
            fontWeight={700}
            alignItems="center"
            justifyContent="center"
            w="20vw"
            textAlign="center"
            cursor="pointer"
            transition="all 0.3s ease"
            textTransform="uppercase"
            borderRadius="10px"
            _hover={{
              bg: "gray.800",
              boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.05)",
            }}
            _active={{
              transform: "scale(0.98)",
              boxShadow: "0 3px 5px rgba(0, 0, 0, 0.15)",
            }}
            onClick={handleWalletClick}
          >
            WALLET
            {/* Nút nháy đỏ cho WALLET */}
            {!isWalletClicked && (
              <Box
                position="absolute"
                top="-5px"
                left="-1px"
                width="12px"
                height="12px"
                bg="red.500"
                boxShadow="0 0 8px rgba(255, 0, 0, 0.7), 0 0 12px rgba(255, 0, 0, 0.5)"
                borderRadius="50%"
                animation="puls 1.5s infinite ease-in-out"
                zIndex={999}
              />
            )}
          </Flex>
        </Flex>

        {/* <Menu>
          <MenuButton
            as={Button}
            rightIcon={<HamburgerIcon fontSize={'24px'} fontWeight={'900'} />}
            bg="transparent"
            border="none"
            _hover={{ bg: "none" }}
            _active={{ bg: "none" }}
          >
          </MenuButton>
          <MenuList textColor={'black'}>
            <MenuItem as={RouterLink} to="/wallet" textColor={'black'}>Wallet</MenuItem>
            <MenuItem as="a" href="https://discord.gg/AZ7HEYy5nX" target="_blank" textColor={'black'}>Discord</MenuItem>
            <MenuItem as="a" href="https://t.me/dislandshub" target="_blank" textColor={'black'}>Join Our Channel</MenuItem>
            <MenuItem as="a" href="https://www.dislands.com" target="_blank" textColor={'black'}>Website</MenuItem>
          </MenuList>
        </Menu> */}
      </Flex>
    </Center>
  );
}

export default Navbar;
